import React from 'react';
import Helmet from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../sass/global/styles.scss';

const Layout = ({ children, location, wordpressUrl }) => (
  <div id="layout">
    <Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;1,300;1,700&display=swap" rel="stylesheet" />
    </Helmet>
    <Header location={location} />
    <div className="main">{children}</div>
    <Footer location={location} />
  </div>
)

export default Layout
